import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from '@angular/router';

import { provideToastr } from 'ngx-toastr';
import { appRoutes } from './app.routes';
import { provideMarkdown } from 'ngx-markdown';

registerLocaleData(localeKo);

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: false,
        filter: (req) => false,
      }),
    ),
    provideRouter(appRoutes, withViewTransitions()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    importProvidersFrom(BrowserModule, BrowserAnimationsModule),

    provideToastr(),
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'ko' },
    provideMarkdown(),
  ],
};
